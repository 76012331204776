import { render, staticRenderFns } from "./Welcome.vue?vue&type=template&id=29b355b8&"
import script from "./Welcome.vue?vue&type=script&lang=js&"
export * from "./Welcome.vue?vue&type=script&lang=js&"
import style0 from "./Welcome.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/common/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2FWelcome.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports